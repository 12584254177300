body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-select-container {
  position: relative;
  width: 200px;
}

.custom-select {
  border: 2px solid #007bff;
  border-radius: 10px;
  padding: 8px;
  font-size: 16px;
  background-color: #f8f9fa;
  color: #343a40;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
  align-items: center;
}

.custom-select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: none;
}

.custom-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 2px solid #007bff;
  border-radius: 12px;
  background-color: #f8f9fa;
  z-index: 1000;
}

.custom-select-option {
  padding: 8px;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
}

.custom-select-option:hover {
  background-color: #e9ecef;
}

/* Estilos específicos para dispositivos móviles */
.custom-select-container.mobile {
  width: 100%;
}

.custom-select.mobile {
  font-size: 14px;
}

.custom-select-option.mobile {
  font-size: 14px;
}